 .bronze {
     background: $bronze;
     color: #fff;
 }



 .master {
     background: $master;
     color: #fff;
 }



 .diamond {
     background: $diamond;
     color: #fff;
 }



 .grandmaster {
     background: $grandmaster;
     color: #fff;
 }



 .gold {
     background: $gold;
     color: #fff;
 }



 .platinum {
     background: $platinum;
     color: #fff;
 }

 .btn-blue {
     background: $blue !important;
 }