.new {
    color: $danger;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    right: .5rem;
}

.newHover {
    color: rgb(240, 198, 12);
}

.comming-soon {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    width: 100%;
    z-index: 2000;
}