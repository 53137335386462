@media only screen and (max-width: 700px) {
    .x-width {
        width: 100% !important;
        margin: 0 !important;
        padding: .4rem !important;
        justify-content: space-between;
        align-items: center;
    }

    .messages_container {
        padding: 0rem !important;
    }

    #nav-profile-tab {
        margin: 0;
    }
}