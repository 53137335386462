.membership {
    .headBox {
        .btn-danger {
            background: #EE2C4C !important;
            color:#fff;
        }
    }
}

.smallBodyText{
    max-width: 446px;
}