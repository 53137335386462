.NotificationMessage {
    max-width: 654px;
    min-height: 330px;
    background-image: url("../../../public/images/messageBg.png");
    background-position: center;
    background-size: cover;
    text-align: center;
    margin-top: 12%;
    padding: 2rem 0;
    border-radius: 6px;


    h2 {
        margin: 1rem 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 35px;
        text-decoration-line: underline;
        color: #FC9828;
    }

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 35px;
        margin-top: 3rem;
        align-items: center;
        color: #FFFFFF;
    }

    .btn-close {
        position: absolute;
        width: 40px;
        height: 40px;
        border: none;
        background: #1C3E58 !important;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        margin-top: -2.2rem;
        right: -4rem;

        &:hover {
            background: red !important;
        }
    }

}

@media only screen and (max-width: 700px) {
    .btn-close {
        right: .4rem !important;
        margin-top: -.7rem !important;
    }
}