$purple: #813aa7;
$primary: #918991;
$bronze: #539254;
$master: #398080;
$diamond: #408bb5;
$grandmaster: #7037c3;
$gold: #ab801c;
$platinum: #7f1f09;
$blue: #1c3e58 ;
$wallet:#798996;
$danger:#f40d0d;