.calendarCard__header {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.calendarCard__icon {
  font-size: 20px;
  cursor: pointer;
}
