 .changepassword {
     border: 1px solid silver;
     padding: 1rem;
     text-align: left;
     margin-top: 2rem;

     .btn,
     .btn:focus {
         background: #1c3e58 !important;
         border: none;
     }

     input {
         margin: 1.6rem 0;
         padding: 1.5rem !important;
     }

     border-radius:.4rem;
     -webkit-border-radius:.4rem;
     -moz-border-radius:.4rem;
     -ms-border-radius:.4rem;
     -o-border-radius:.4rem;
 }