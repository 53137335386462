.comming-soon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    width: 100%;
    z-index: 2000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgb(225, 216, 200);
    font-size: 30px;
    font-weight: body;
}