.TestimonialsCards {
    margin-top: 6rem;

    .card {
        cursor: pointer;
    }

    .card-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #000000;
    }

    .card-text {
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
    }

    h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
    }

    .rankText {
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
        color: #000000;
    }

    .lText {
        position: absolute;
        margin-top: -3.3rem;
        margin-left: 5rem;
    }

    .btn {

        padding: 10px;
        gap: 10px;
        height: 47px;
        background: #EE2C4C;
        border-radius: 8px;
        margin-top: 2rem;
    }
}

.inputX {
    input {
        max-width: 472px;
        height: 43px;
        background: #EFF2F3;
        box-shadow: inset 0px 0.25px 6px rgba(0, 0, 0, 0.25);
        border-radius: 6px;

    }
 

    .btn2 {
        position: absolute;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        flex: none;
        order: 0;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        max-width: 176px;
        margin-top: -2.7rem;
        height: 43px;
        right: 5rem;
    }

    .btn3 {
        height: 43px;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }
}