.task {
  // background-color: aqua !important;
}

.taskCards {
  .card {
    // min-width: 250px;
    margin: 20px 0;
    min-height: 290px;
    // background-image: url("../../../public/images/Task/bg.png");
    background-size: cover;
    border: 0 !important;
  }
  .visitImg {
    margin-top: -4.5px;
    margin-right: 5px;
  }
  .textSection,
  .text-bold {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }
  hr {
    width: 90%;
    margin: 10px auto;
  }
}

