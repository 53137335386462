.failed {
  width: 100px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

li {
  list-style: none !important;
}

.first-header {
  height: 40px;
  left: 0px;
  top: 0px;
  background: #46464680;

  .on-border {
    border-right: 1px solid #fc9828;
    padding-right: 10px;
  }

  ul {
    display: flex;
    margin-top: 5px;

    li {
      margin-right: 35px;
      font-weight: 400;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      a {
        color: #ffffff;

        .fa {
          color: #fc9828;
        }
      }

      a:hover {
        // color: #fc9828;
        text-decoration: none !important;
      }
    }
  }

  .second-ul {
    margin-top: -30px;
    margin-right: -20px;
    float: right;
  }
}

.nav-link {
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 24px;
  color: #ffffff !important;

  &:hover {
    color: #fc9828;
  }
}

.bg-purple {
  background: #4e1271 !important;
}

.login-btn {
  padding: 5px;
  width: 120px;
  border: 1px solid #ffffff;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
}

.login-btn {
  border: 1px solid #fff !important;
  color: #fff !important;
}

.user-btn {
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 15px;
  background-color: transparent !important;
}

.user-btn:hover {
  background-color: #ffffff !important;
  color: #000 !important;
}

.login-btn:hover {
  color: #ffffff !important;
  background: #fc9828;
  border: 1px solid #fc9828;
}

.heaad-btn {
  width: 120px !important;
}

.registration-btn {
  padding: 5px;
  color: #ffffff;
  background: #fc9828;
  border: 1px solid #fc9828;
  box-sizing: border-box;
  border-radius: 6px;
}

.registration-btn:hover {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
}

.publisher-btn {
  color: #ffffff;
  background: #fc9828;
  border: 1px solid #fc9828;
  border-radius: 6px;
}

.bg {
  background-image: url("../../../public/images/headBg.png");
  min-height: 70vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
  background-position: center;
  // background-color: rgb(70, 33, 92);
  background-color: linear-gradient(
    180deg,
    rgba(24, 5, 40, 0) 0%,
    gb(70, 33, 92) 81.32%,
    #19052a 100%
  );
  background-blend-mode: screen;
  mix-blend-mode: darken;
}

.bg-content {
  padding-top: 4rem;

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 45px;
    text-align: center;
    color: #ffffff;
    width: 60%;
    margin: auto;
  }
}

.navbar-light .navbar-toggler {
  color: rgba(172, 166, 166, 0.918) !important;
  padding: 8px 12px !important;
  border: none !important;
  font-size: 18px !important;
  cursor: pointer;
}

/* header ends */

// footer start
footer {
  font-size: 14px;
  color: rgb(207, 207, 207);
  background: #333333;
}

.footer-list1 {
  margin-top: 30px;
  margin-bottom: 30px;

  li {
    cursor: pointer;
    list-style: "✓ " !important;
  }

  li:hover {
    color: #fc9828;
  }
}

.footer-list2 {
  li {
    display: inline;
    margin-left: 15px;
    font-size: 12px;

    &:hover {
      color: #fc9828;
    }
  }
}

// footer ends
.publisher-btn,
.user-btn {
  width: 210px !important;
  height: 47px !important;
}

// media queries
@media (min-width: 992px) {
  .footer-list1,
  .footer-list2 {
    li {
      display: inline;
      margin-left: 20px;
    }
  }
}

@media (max-width: 992px) {
  .bg-content {
    padding-top: 60px !important;

    h2 {
      width: 100%;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 28px;
    }

    p {
      font-size: 21px !important;
    }
  }

  .bg-sm-white {
    background-color: #ffffff !important;
  }

  .nav-link {
    color: #000 !important;
  }

  .bg {
    min-height: 100vh;
  }
}

.en-txt {
  position: absolute;
  border-left: 1px solid #777575;
  padding-left: 5px;
  margin-left: 5px;
  margin-top: 10px;
}

.border-span {
  display: inline-block;
  position: absolute;
  width: 40px;
  margin-right: 5px;
  margin-left: 10px;
  margin-top: 10px;
  border: 0.25px solid #919090 !important;
  transform: rotate(-180deg) !important;
  -webkit-transform: rotate(-180deg) !important;
  -moz-transform: rotate(-180deg) !important;
  -ms-transform: rotate(-180deg) !important;
  -o-transform: rotate(-180deg) !important;
}

.social-link {
  margin-left: 50px;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

// loading

.loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3000 !important;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;

  .load-content {
    margin: auto;

    i {
      font-size: 80px !important;
    }
  }
}

.bell {
  width: 26px;
  height: 26px;
}

.link {
  cursor: pointer !important;
}

.scroll-top {
  width: 40px !important;
  background-color: gray !important;
  color: white !important;
  border: 0px !important;
  height: 40px !important;
  position: fixed !important;
  right: 20px !important;
  bottom: 40px !important;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.scroll-top svg {
  fill: #fc9828 !important;
}

.scroll-top:hover {
  background-color: #fc9828 !important;
}

.scroll-top:hover svg {
  fill: white !important;
}

.notify {
  color: #fc2d49 !important;
  font-size: 10px !important;
  position: absolute;
  margin-left: 14px;
}

.dropdown {
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.005em;
    color: #0f1117;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 4px;
    margin-right: 4px;
  }

  .fa-angle-down {
    font-size: 20px;
    color: #fabe28 !important;
  }
}

.dashboard-nav {
  .nav-link {
    margin-left: 0px !important;
  }
}

.search {
  height: 40px;
  width: 302px;
  margin-top: 5px;
  margin-right: 15px;
  background-color: #ffffff !important;
  padding-left: 40px;
  border-radius: 60px;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
}

.search-Icon {
  position: absolute;
  margin-top: 18px;
  margin-left: 18px;
  font-size: 16px;
  color: #8b858591 !important;
}

.text-danger {
  color: #ef131a !important;
}

small {
  font-size: 12px !important;
}

.btn-purple {
  background: #813aa7;
  color: white;

  &:hover {
    color: white;
  }
}

.b {
  font-weight: bold;
}

.text-purple {
  color: #9353a1;
}

.rounded-x {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

// .react-calendar__navigation {
//   // display: none !important;
// }

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 0 !important;
  font-family: consolas !important;
  line-height: 0.125em !important;
  margin-top: 20px !important;
}

.react-calendar__tile--active {
  background: #fff !important;
  color: #fabb18 !important;
}

.count-down {
  position: fixed !important;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 13 !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-top: 220px;
  font-size: 35px;
  color: #fabb18;

  .span_text {
    position: fixed;
    bottom: 0px;
  }
}

.welcome {
  .text-muted {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 19px;
    color: #b5b5b5 !important;
  }
}

.confetti {
  width: 100%;
  padding-top: 35vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;

  h5 {
    color: #e0d89f;
  }
}

.claim_coin {
  background: rgb(218, 189, 118);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  font-weight: bold;
  text-shadow: 1px 2px 2px rgb(255, 203, 92);
  padding: 10px 40px;
}

.soonText {
  opacity: 50%;
}

.page-link {
  color: #2f2f5a;
  margin: 3px;
  // width: 40px;
  // height: 40px;
  text-align: center;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2f2f5a !important;
  border-color: #2f2f5a !important;
}

.user_avatar_mini {
  text-transform: capitalize;
  color: rgb(238, 230, 230);
  opacity: 80%;
  text-shadow: 2px 2px rgb(71, 49, 49);
  background: #5a562f;
  width: 35px !important;
  height: 35px !important;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  margin-left: -16px;
  margin-top: -5px;
  border: 3px solid rgb(182, 153, 91) !important;
}

.miniavatar {
  width: 35px;
  height: 35px;
  border: 2px solid rgb(182, 153, 91) !important;
}

.btn-hero {
  padding-top: 10px !important;
}

.nav-white-bg {
  a.nav-link,
  li.nav-item {
    color: #000000 !important;

    &:hover,
    &:focus {
      color: #390075 !important;
    }
  }

  .login-btn {
    border: 1px solid #7b7979 !important;
    color: #000000 !important;

    &:hover {
      border: none !important;
    }

    border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
  }
}

.active-link {
  color: #fc9828 !important;
}
